#main {
	min-height: 100vh;
}

img {
	max-width: -webkit-fill-available;
}

#no-margin p {
	margin: 0;
}

* {
	scrollbar-width: auto;
	scrollbar-color: #c1c1c1;
}

::-webkit-scrollbar {
	width: 10px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #c1c1c1;
	border-radius: 10px;
}

*:hover::-webkit-scrollbar {
	width: 10px;
	height: 8px;
}

.MuiDataGrid-filterFormValueInput .MuiTextField-root {
	margin-top: 0
}

.MuiTableRow-root:has(textarea) {
	vertical-align: top;
}

.MuiTabs-scrollButtons.Mui-disabled {
	opacity: 0.3;
}

.detailNavigationButtons {
	position: sticky;
	bottom: 0;
	margin: auto -9.25rem -1.5rem -9.35rem;
	padding: 1rem 0;
	z-index: 9999;
}

@media (max-width: 1561px) {
	.detailNavigationButtons {
		margin: auto 0 -1.5rem 0;
		padding: 1rem 0;
		background: transparent;
		/* Halbtransparenter Hintergrund */
		backdrop-filter: blur(5px);
		/* Weichzeichner (Blur) */
		-webkit-backdrop-filter: blur(5px);
		/* Safari Unterstützung */
		border-radius: unset;
	}
}

@media (max-width: 480px) {
	.detailNavigationButtons {
		margin: auto 0 -1.5rem 0;

	}
}

.buit-markdown-editor,
.buit-markdown-editor-error {
	font-family: Avenir Next LT Pro, sans-serif;
	margin-top: 8px;
	max-height: 25rem;
	overflow-y: scroll;
	background-color: #fff;
	border: 1px solid #EAF0F1;
	border-radius: 4px;
}

/*Hide unwanted popup container Quick fix*/
.mdxeditor-popup-container {
	display: none !important
}

.buit-markdown-editor-error {
	border-color: #d32f2f;
}

.buit-markdown-editor .mdxeditor-root-contenteditable {
	line-height: 1em;
}

.buit-markdown-editor span[data-lexical-text] {
	line-height: 1.438rem;
}

.buit-markdown-editor .mdxeditor-toolbar {
	background-color: #EAECF3;
	border-radius: 0;
}

.pinned-right-column {
	position: sticky;
	right: 0;
	background-color: #949EC20A;
	/* Maintain background color */
	z-index: 2;
	/* Ensure the cell has a fixed minimum width */
	border-left: 1px solid #EAF0F1;
	width: 50px;
}